.swiper {
  width: 100%;
  height: 100%;
}

.fullVisa .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 90% !important;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination{
  bottom: 50px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 20px;
  color: #000;
  opacity: 1;
  background-color: #fff;
  padding: 20px ;
  display: flex;
  justify-content: center;
  align-items: center;

}

.swiper-pagination-bullet-active {
  color: #fff;
  background: rgba(3, 21, 47, 1);
  /* padding: 5px; */
}

