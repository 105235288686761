.hop-main{
    height: 800px;
}

.ptv-main{
    height: 750px;
}

.pack-top{
    flex-direction: row;
}

.pt-txt-left h3{
    font-size: 46px;
    font-weight: 500;
    line-height: 57px;
}

.pt-txt-right p{
    width: 80%;
}

.pack-bottom{
    flex-direction: row;
    align-items: flex-start;
    height: 100%;
}
.pb-left{
    flex-direction: column;
}

.pb-left button{
    padding: 0;
}

.overlay{
    font-weight: 400;
    /* font-family: "Clash Display", */
}

.hov{
    height: 1200px;
}

.hov-desk{
    display: block;
}

.hov-mobile{
    display: none;
}

.main-cont-packages{
    width: 85%;
    height: 100%;
}

.pb-right{
    height: 95%;
}

#hop-slid-card-img{
    height: 70%;
}

.pb-right-2{
    height: 85%;
}

.main-cont-places{
    top:50px ;
    height: 100%;
}

.ptvb-left{
    position: relative;
    top: -10px;
}



@media only screen and (max-width:600px){
    .hop-main{
        height: 750px;
    }
    

    .ptv-main{
        height: 750px;
    }

    .pack-top{
        flex-direction: column;
    }

    .ptvb-left{
        position: relative;
        top: -45px
    }

    .pt-txt-left h3{
        font-size: 30px;
        font-weight: 500;
        line-height: 57px;
        margin-left: 10px;
        margin-bottom: 0;
    }
    .pt-txt-right p{
        width: 100%;
    }
    .pack-bottom{
        flex-direction: column-reverse;
        align-items: center;
    }
    .pb-left{
        flex-direction: row;
    }

    .pb-left button{
        padding: 0 5px;
    }

    .overlay{
        font-size: 20px;
        font-weight: 500;
        /* font-family: sans-serif; */
        letter-spacing: 2px;
    }

    .hov{
        height: 1650px;
    }
    .hov-desk{
        display: none;
    }

    .hov-mobile{
        display: block;
    }
    .hovm{
        height: 1650px;
    }

    .main-cont-packages{
        width: 90%;
        height: 90%;
    }

    .pb-right{
        height: 100%;
    }

    #hop-slid-card-img{
        height: 65%;
    }

    .pb-right-2{
        height: 85%;
    }
    .main-cont-places{
        top:20px ;
        height: 65%;
    }
}



.hrvtb-link{
    background-color:rgba(2, 71, 134, 1);
    transition: 1s;
}

.hrvtb-link:hover{
    background-color:rgba(3, 21, 47, 1);

}

.hrv-main{
    height: 450px;
}
.hrv-main-cont{
    height: 80%;
    width: 85%;
    padding: 0 40px;
    background-image: url("./assets/homeReg.png");
    background-size: cover;
}

.hrv-top{
    justify-content: flex-start;
    align-items: flex-start;
    flex-basis: 80%,
}

.hrvt-box{
    width: 40%;
}
.hrv-bottom-desk{
    display: flex;
}
.hrv-bottom-mob{
    display: none;
}


@media only screen and (max-width:600px){
    .hrv-main{
        height: 600px;
    }
    .hrv-main-cont{
        height: 100%;
        width: 95%;
        padding: 0 15px;
        background-image: url("./assets/homeRegMob.png");
        background-size: cover;
        background-position: center;
    }
    .hrv-top{
        justify-content: center;
        align-items: center;
        flex-basis: 60%,
    }
    .hrvt-box{
        align-items: center;
        width: 95%;
        text-align: center;
    }

    .hrv-bottom-desk{
        display: none;
    }
    .hrv-bottom-mob{
        display: flex;
    }
    
}