.footer {
    width: 100%;
    background-color: #000047;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #fff;
}

/* display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "end", margin: "20px 0" */

.imp-link {
    width: 30%;
    align-items: end;

}

.imp-link ul li {
    list-style-type: none;
    font-size: 20px;

}

.imp-link ul li a {
    text-decoration: none;
    color: #fff;
}

.footer-top {
    flex-direction: row;
}

.footer-main {
    height: 510px;
}

.foot-line {

    width: 2px;
    height: 90%;
}

.ft-right{
    flex-direction: row;
}
.ftr-left{
    align-items: center;
}

.footer-bottom{
    flex-direction: row;
}

.footer-bottom ul{
    font-size: 16px;
}

.foot-line2{
    height:0.5px;
    width:300px;
}

.footer-bottom2{
    align-items: flex-end;
}

.footer-bottom2-left{
    align-items: flex-start;
}

.ftr-left2{
    margin-bottom: 5px;
}

.ftr-left3{
    margin-top: 55px;
    position: relative;
    left: 50px;
}

.cred-foot-1{
    margin-left: 25px;
    flex-basis:40%;
}

.cred-main{
    padding: 0 45px;
}

.imp-links-foot-desk{
    display: block;
}

.imp-links-foot-mob{
    display: none;
}


@media only screen and (max-width:600px) {

    .imp-links-foot-desk{
        display: none;
    }
    
    .imp-links-foot-mob{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        margin-bottom: 0;
    }

    .cred-main{
        padding: 0 25px;
    }

    .cred-foot-1{
        margin-left: 0px;
        flex-basis:60%;
    }

    .ftr-left3{
        margin-top: 0px;
        position: relative;
        left: 0px;
        margin-bottom: 45px;
    }
    .footer {
        width: 100%;
        background-color: #000047;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column-reverse;
    }

    .imp-link {
        width: 100%;
        align-items: center;
    }

    .imp-link ul li a {
        color: #fff;
    }

    /* .developer-crds h6{
        font-size: 6px;
    } */

    .footer-top {
        flex-direction: column;
    }

    .footer-main {
        height: 1300px;
    }

    .foot-line {
        margin-top: 30px;
        height: 2px;
        width: 95%;
    }
    .ft-right{
        flex-direction: column;
    }
    .ftr-left{
        align-items: flex-start;
        margin-left: 20px;
    }

    

    .ftr-left ul{
        padding-left: 0;
    }
    .footer-bottom{
        flex-direction: column-reverse;
    }
    .footer-bottom ul{
        font-size: 12px;
    }
    .foot-line2{
        /* height:2.5px;
        width:100%; */
        display: none;
    }
    .footer-bottom2{
        align-items: flex-end;
        margin-left: 15px;
    }

}