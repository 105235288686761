.about-title-box{
  font-size: 60px;
  font-weight: 400;
  width: 35%;
}

.about-banner{
  height: 310px;
  background-size: 1500px 390px;
}
.about-banner .overlay{
  height: 100%;
}

.about-title-box h1{
  font-size: 60px;
  font-weight: 400;
}

.akm-cont{
  width: 90%;
}

.akmr-btn{
  border: 1px solid #fff;
  color: #fff;
  cursor: pointer;
  transition: 1s;
}

.akmr-btn:hover{
  border: 1px solid rgba(151, 171, 182, 1);
  color: rgba(151, 171, 182, 1);
}

.about-mission{
  height: 400px;
}

.am-cont{
  flex-direction: row;
  width: 65%;
}
.amc-right{
  position: absolute;
  height: 350px;
  width: 45%;
  right: 75px;
}

.amc-left h2{
  font-size: 46px;
}

.about-vision{
  height: 400px;
}

.av-cont{
  flex-direction: row-reverse;
  width: 65%;
}

.avc-right{
  position: absolute;
  height: 350px;
  width: 45%;
  left: 75px;
}

.about-values{
  height: 550px;
}

.av-title{
  width: 30%;
  align-items:center;
}

.av-title h2{
  font-size: 46px;
}

.av-boxes{
  flex-direction: row;
}

.about-know-more{
  flex-direction: row;
  height: 1200px;
}

.akm-cont{
  flex-direction: row;
}

.akm-left h2{
  font-size: 46px;
}
.akm-left{
  align-items: center;
  flex-basis: 43%;
}

.akm-right{
  flex-basis: 43%;

}

.akmr-left{
  width: 100%;
}

.akmr-right{
  width: 100%;
}

.akmr-btn{
  width: 185px;
}
.akm-w-map{
  position: absolute;
  left: 0; 
  bottom: 0;
}

.ac-title h3{
  font-size: 46px;
}

.ac-title {
  width: 40%;
}

.aboutLine1{
  display: block;
}

.ac-body{
  flex-direction: row;
}

.miss-desk{
  display: block;
  
  margin-bottom: 20px;
}

.amkrlb1{
  margin: 20px;
}

.akm-w-map{
  display: block;
}

.akm-w-map-mob{
  display: none;
}


@media only screen and (max-width:600px){

  .akm-w-map{
    display: none;
  }
  
  .akm-w-map-mob{
    display: block;
  }

  .amkrlb1{
    margin: 30px 20px;
  }
  .about-title-box{
    font-size: 34px;
    font-weight: 400;
    width: 100%;
    text-shadow: 2px 2px 5px #000;
  }
  .about-banner{
    height: 310px;
    background-size: contain;
  }
  .about-banner .overlay{
    height: 40%;
  }

  .about-mission{
    height: 600px;
  }
  .av-cont{
    flex-direction: column;
    width: 100%;
  }
  .amc-right{
    position: relative;
    height: 250px;
    width: 85%;
    left: 0px;
    top: 60px;
  }
  .amc-left h2{
    font-size: 30px;
    padding: 5px 20px;
    margin-top: 40px;
  }

  .amc-left p{
    padding: 5px 20px;
  }

  .about-vision{
    height: 600px;
  }

  .av-cont{
    flex-direction: column;
    width: 100%;
  }

  .am-cont{
    flex-direction: column;
    width: 100%;
  }

  .avc-right{
    position: relative;
    height: 250px;
    width: 85%;
    left: 0px;
    top: 60px;
  }

  .about-values{
    height: 1550px;
  }

  .av-title{
    width: 80%;
    flex-basis: 5%;
    align-items: flex-end;
  }

  .av-title h2{
    font-size: 30px;

  }

  .av-boxes{
    flex-direction: column;
  }
  .av-box1{
    margin: 20px 0;
  }

  .about-know-more{
    flex-direction: column;
    height: 1750px;
  }
  
  .akm-cont{
    flex-basis: 50%;
    flex-direction: column;
    margin-top: 150px;
  }
  .akm-left{
    align-items: flex-start;
    flex-basis: 15%;
    margin: 20px;
  }

  .akm-left h2{
    font-size: 30px;
  }

  .akm-right{
    flex-basis: 85%;
    
  }

  .akmr-left{
    width: 45%;
    margin: 0 10px;
    margin-left: 20px;
  }

  .akmr-right{
    width: 45%;
    margin: 0 10px;
    margin-right: 20px;
  }

  .akm-cont{
    width: 100%;
  }
  .akmr-btn{
    width: 145px;
  }
  .akm-w-map{
    position: static;
    flex-basis: 50%;
  }
  .ac-title {
    width: 100%;
  }
  .ac-title h3{
    font-size: 30px;
  }
  .aboutLine1{
    display: none;
  }
  .ac-body{
    flex-direction: column-reverse;
  }
  
}

/* FOUNDER SECTION */

.about-ceo{
  height: 800px;
}


@media only screen and (max-width:600px){
  .about-ceo{
    height: 1200px;
  }
}