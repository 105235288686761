.side-bar-menu ul li{
    cursor: pointer;
    padding: 5px 10px;
    transition: all ease 0.2s;
    width: 110%;
    
}
.side-bar-menu ul li a{
    text-decoration: none;
    color: #fff;
}

.side-bar-menu ul li:hover{
    background-color: #fff;
    color: #000047;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 5px 5px 10px #000;
    transform: translateX(10px);
}



.side-bar-menu ul .active{
    background-color: #fff;
    color: #000047;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 5px 5px 10px #000;
    transform: translateX(10px);
}
