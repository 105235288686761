.col {
    height: 40vh;
}

.col2 .parent {
    height: 750px;
    width: 100%;
}

.instagram h4 {
    font-size: 45px;
}

.instagram .row {
    width: 100%;
    height: 100%;
}

.tripsy-title,
.tripsy-insta {
    margin: 25px 600px;
    width: 400px;
    box-shadow: 5px 5px 10px #000;
}

.tripsy-title p {
    padding-top: 20px;
    padding-left: 20px;
    font-size: 100px;
    transform: rotate(-30deg);
}

.tripsy-insta p {
    padding-top: 20px;
    padding-left: 20px;
    font-size: 100px;
}

.my-revs {
    height: 2400px;
}

.reviews-cont {
    flex-direction: row;
}

/* .my-revs .carousel-item{
    min-height: 200px;
} */

.my-revs .carousel-item .carousel-body {
    height: 200px;
}

.insta-iframe {
    height: 1000px;
}

.revs-line {
    height: 100%;
    width: 1px;
}

.rcl-title h3 {
    font-size: 46px;
    justify-content: flex-start;
}


.rclb-btn:hover{
 background: rgba(151, 171, 182, 0.3);
}


/* #Blog Button */
/* .blogs-btn{
    position: relative;
    top: 65px;
} */

.blogs-btn-cont,
.follow-btn {
    background-color: #fff;
    cursor: pointer;
    transition: 1s;
}

.blogs-btn-cont:hover,
.follow-btn:hover {
    background-color: rgba(151, 171, 182, 0.3);
}

.rcl-content {
    justify-content: flex-start;
}

.rcl-btn {
    justify-content: flex-start;
}

.rclb-btn {
    width: 35%;
}

.rb-ctrl-btn {
    left: 225px;
    top: -50px;

}
.reviews-cont{
    width: 85%;
}

.rb-main{
    width: 600px;
}

.home-blogs{
    height: 950px;
}

.blogs-title{
    flex-basis: 10%;
}

.blogs-title h3{
    font-size: 46px;
    font-weight: 500
    
}

.blogs-main-content{
    flex-direction: row;
    justify-content: space-between;
    flex-basis: 80%;
}

.blogs-main-content-right{
    flex-basis: 49.5%;
}

.bmcl-b-left h4{
    font-size: 24px;
}

.bmcl-top{
    flex-basis: 78%;
}

.bmcl-bottom{
    flex-basis: 22%;
    flex-direction: row;
}

.bmcl-b-left{
    justify-content: space-between;
}

.bmcl-b-right a{
    display: none;
}

.bmcl-b-right svg{
    display: block;
}

.bmcr-b-left h4{
    font-size: 24px;
}

.bmcr-b-left{
    flex-direction: column;
}
.bmcr-bottom{
    flex-direction: row;
}

.tripsy-insta-title{
    flex-direction: row;
    height: 150px;
}

.insta-title-left h3{
    font-size: 46px;
}

.insta-title-left{
    width: 55%;
}

.insta-title-right{
    width: 25%;
}

.follow-btn{
    height: 55px;
}
.tripsy-insta-frame iframe{
    height: 1050px;
}

.rclc-left{
    align-items: flex-start;
}

.rev-slider-title{
    margin-top:40px;
}

.instagram{
    margin-top: 50px;
}

.blogs-main-content-left{
    flex-basis: 49.5%;
    height: 100%;
}

.bmcl-b-left div p, .bmcr-b-left div p{
    padding: 0 25px;
}

.bmcr-top{
    flex-basis: 49.5%;
}


@media only screen and (max-width:600px) {

    .bmcr-top{
        flex-basis: 45%;
    }
    

    .bmcl-b-left div p, .bmcr-b-left div p{
        padding: 0 15px;
    }

    .col2 .parent {
        height: 300px;
        width: 125%;
    }

    .instagram h4 {
        font-size: 25px;
    }

    .tripsy-title,
    .tripsy-insta {
        margin: 25px 20px;
        padding: 10px;
        width: 350px;
    }

    .tripsy-title p,
    .tripsy-insta p {
        padding-top: 20px;
        font-size: 100px;
    }

    .my-revs .carousel-item .carousel-body {
        height: 400px;
    }

    .insta-iframe {
        height: 375px;
    }

    .my-revs {
        height: 2800px;
    }

    .col {
        height: 600px;
    }

    .reviews-cont {
        flex-direction: column;
    }

    .revs-line {
        height: 1px;
        width: 100%;
        margin: 20px 0;
    }

    .rcl-title h3 {
        font-size: 30px;
        justify-content: center;
    }

    .rcl-content {
        justify-content: center;
    }

    .rcl-btn {
        justify-content: center;
    }

    .rclb-btn {
        width: 85%;
    }
    .rb-ctrl-btn {
        left: 100px;
        top: -50px;
    
    }

    .reviews-cont{
        width: 100%;
    }

    .rb-main{
        width: 400px;
    }

    .home-blogs{
        height: 1350px;
    }
    .blogs-title{
        flex-basis: 5%;
    }
    .blogs-title h3{
        font-size: 30px;

    }
    .blogs-main-content{
        flex-direction: column;
        justify-content: flex-start;
        flex-basis: 95%;
    }

    .blogs-main-content-right{
        flex-basis: 59.5%;
    }

    .blogs-main-content-left{
        /* margin: 40px 0; */
        flex-basis: 40%;
        height: 400px;
    }
    .bmcl-b-left h4{
        font-size: 20px;
    }
    .bmcl-top{
        flex-basis: 60%;
    }
    .bmcl-bottom{
        flex-basis: 40%;
        flex-direction: column;
    }

    .bmcl-b-left{
        justify-content: flex-start;
    }

    .bmcl-b-left div p{
        font-size: 14px;
    }
    .bmcl-b-left div{
        transform: translateX(-10px);
    }

    .bmcl-b-right a{
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(2, 71, 134, 1);
        width: 95%;
        height: 55px;
        border-radius: 30px;
        color: rgba(2, 71, 134, 1);
        text-decoration: none !important; 
        font-size: 16px;
        font-weight: 500;
    }
    
    .bmcl-b-right svg{
        display: none;
    }
    .bmcr-top{
        margin-top: 25px;

    }
    .bmcr-b-left h4{
        font-size: 20px;
    }

    .bmcr-b-left div p{
        font-size: 14px;
    }

    .bmcr-b-left div{
        transform: translateX(-10px);
    }

    .bmcr-b-left{
        flex-direction: column;
    }

    .bmr-bottom{
        flex-basis: 47%;
    }

    .bmcr-bottom{
        flex-direction: column;
    }

    .tripsy-insta-title{
        flex-direction: column;
        height: 150px;
    }
    .insta-title-left{
        width: 75%;
    }
    .insta-title-left h3{
        font-size: 30px;
        text-align: center;
    }
    .insta-title-right{
        width: 50%;
    }

    .follow-btn{
        height: 55px;
    }

    .tripsy-insta-frame iframe{
        height: 425px;
    }

    .rcl-title{
        justify-content: center;
    }

    .rclc-left{
        align-items: center;
    }

    .rev-slider-title{
        margin-top:10px;
    }

}