.search-bar{
  position: relative;
  z-index: 99;
  top: -10px;
}

.visa-title{
  height: 310px;
  margin: 50px 0;
}


.head-box{
  width: 30%;
}
.head-box h2{
  font-size: 46px;
}
.headbox-desc{
  width: 80%;
}

.v-options-box, .v-options{
  width: 65%;
}

.visat-title{
  height: 175px;
}

.vt-head-box{
  width: 40%;
  flex-basis: 65%;
}

.vt-head-box h3{
  font-size: 46px;
}

.vt-options-box{
  width: 20%;
  flex-basis: 35%;
}

.vt-options-box h6{
  font-size: 24px;
}

@media only screen and (max-width:600px){
  .visa-title{
    height: 650px;
    margin: 20px 0;
    margin-left: 5px;
  }
  .head-box h2{
    font-size: 30px;
  }
  .head-box{
    width: 100%;
  }
  .headbox-desc{
    width: 100%;
  }

  .v-options-box{
    width: 100%;
  }

  .v-options{
    width:85%;
  }

  .visa-main-row{
    margin-left: -5px;
  }

  .visat-title{
    height: 150px;
  }

  .vt-head-box{
    width: 100%;
    flex-basis: 25%;
  }
  .vt-head-box h3{
    font-size: 30px;
  }

  .vt-options-box{
    width: 100%;
    flex-basis: 15%;
  }
  .vt-options-box h6{
    font-size: 20px;
  }
  
  
  
}

@media only screen and (max-width:600px){
    .search-bar {
        position: relative;
        transition: all 1s ease; /* Add transition */
      }
      
      .sticky {
        position: fixed;
        top: -60px;
        left: 0;
        right: 0;
        z-index: 1000;
        background-color: #000047; /* Adjust as needed */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adjust as needed */
        animation: slideDown 1s ease; /* Add animation */
      }

      .sticky h5{
        visibility: hidden;
        height: 30px;
      }
      .sticky .search-form{
        top: 50px;
      }
      .sticky ul{
        visibility: hidden;
        height: 40px;
      }
      



      @keyframes slideDown {
        0% {
          transform: translateY(-100%);
        }
        100% {
          transform: translateY(0px);
        }
      }
}