.accordion-button:focus {
    box-shadow: none;
    border-color: transparent;
    color: inherit;
  }

  .accordion-button:not(.collapsed) {
    color: inherit;
  }

.faq-btn{
    cursor: pointer;
    transition: 1s;
}

.faq-btn:hover{
    background-color: rgba(151, 171, 182, 1);
}

.faq-cont-outer{
    height: 70vh;
    justify-content: center;
        align-items: center;

    top: 275px;
}

.faq-cont-main{
    height: 750px;
    align-items: center;
}

.fc-top{
    flex-direction: row;
    justify-content: space-between;
}

.faq-contact-top{
    display: flex;
}
.faq-contact-bottom{
    display: none;
}

.faq-intro2-desk{
    display: block;
}
.faq-intro2-mob{
    display: none;
}

.fct-left{
    flex-basis: 34.5%;
}

@media only screen and (max-width:600px){
    .fc-top{
        flex-direction: column;
        justify-content: flex-start;
    }
    .faq-cont-main{
        height: 1250px;
        align-items: flex-start;
    }
    .faq-cont-outer{
        height: 1050px;
        justify-content: flex-start;
        align-items: flex-start;
        top: 45px;
    }
    .faq-contact-top{
        display: flex;
        position: absolute;
        bottom: -125px;
    }
    .faq-contact-bottom{
        display: none;
    }

    .faq-intro2-desk{
        display: none;
    }
    .faq-intro2-mob{
        display: none;
    }

    .fct-left{
        flex-basis: 15%;
        margin: 30px 0;
    }
    
}