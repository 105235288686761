.action-boxes{
    height: 650px;
    padding: 50px;
}

.other-ser-title{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: "15%";
}

.other-ser-title h3{
    font-size: 46px;
    font-weight: 500;
}

.action-boxes .row1{
    flex-direction: row
}

.action-boxes .row1 .col1 .card{
    width: 24rem;
}

.action-boxes .row1 .col2 .card{
    width: 24rem;
}

.action-boxes .row1 .col3 .card{
    width: 24rem;
}

@media only screen and (max-width:600px){

    .action-boxes{
        height: 1450px;
        padding: 0;
    }
    .other-ser-title h3{
        font-size: 30px;
        font-weight: 500;
        text-align: left;
        margin: 0 20px;
    }
    .other-ser-title{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-basis: "2%";
        

    }

    .action-boxes .row1{
        flex-direction: column
    }

    .action-boxes .row1 .col1{
        height: 450px;
    }
    .action-boxes .row1 .col2{
        height: 450px;
    }
    .action-boxes .row1 .col3{
        height: 450px;
    }

    .action-boxes .row1 .col1 .card{
        width: 22rem;
    }
    .action-boxes .row1 .col2 .card{
        width: 22rem;
    }
    
    .action-boxes .row1 .col3 .card{
        width: 22rem;
    }
    
}

.card-btn div{
    background-color: #fff;
    transition: 1s;
}


.card-btn div:hover{
    background-color: rgba(151, 171, 182, 0.3);
}