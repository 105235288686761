.docs-req-div{
    /* margin: 50px 0px; */
    /* width: ; */
    padding: 50px 50px;

    background-color: #000047;
    border-bottom-left-radius: 20%;
    border-bottom-right-radius: 20%;
    margin-bottom: 30px;
    box-shadow: 5px 5px 15px #000;

}

.docs-req-div h4{
    color: #fff;
}

.v-final-top{
    flex-direction: row;
    height: 400px;
    width: 85%; 
}

.vftl-txt h2{
    font-size: 46px;
}

.vftl-content h4{
    font-size: 24px;
}
.v-form{
    width: 90%;
    padding: 20px;
}


@media only screen and (max-width:600px){
    .v-final-top{
        flex-direction: column;
        height: 500px;
        width: 100%;
    }

    .vftl-txt h2{
        font-size: 30px;
    }

    .vftl-content h4{
        font-size: 20px;
    }
    .v-form{
        width: 100%;
        padding: 0;
    }
}