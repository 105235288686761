.tour-card img{
    width: 100%;
    height: 100%;
}

/* @media only screen and (max-width:600px){

} */





