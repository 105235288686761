.hero-blog-main {
    height: 850px;
}

/* .blogs-head-box{
    margin-top: 40px;
} */

.blog-landing{
    height: 750px;
}

.hbt-right {
    display: flex;
}

.blog-overlay {
    align-items: flex-end;
}

/* .b-options {
    height: 1200px;
} */

.h-blog-tags {
    padding: 0 30px;
}

.b-options-main {
    justify-content: center;
    width: 100%;
}

.bl-cont {
    flex-direction: row;
    align-items: end;
}

.blc-left h1{
    font-size: 46px;
}

.blc-right{
    width: 100%;
}

.bs1-text{
    width: 60%;
}

.bs1-img{
    width: 80%;
}

.blog-sec1{
    height: 750px;
}

.blog2-sec3 .bs3-img{
    width: 80%;
}

.blog2-sec3{
    height: 1050px;
}

.blog-sec2{
    height: 750px;
}

.blog2-sec2{
    height: 750px;
}

.bs2-img{
    width: 80%;
}

.hide{
    transform: translateX(120%);
    transition: 0.5s;
}

.unhide{
    transform: translateX(0);
    transition: 0.5s;
}

.reach-pkg-btn{
    display: flex;
}

.htb-l-tags{
    display: flex;
}

.h-blog-text{
    flex-basis: 25%;
}

.hbt-left{
    flex-basis: 40%;
}

.hbt-l-btn{
    display: none;
}

.bs3-text{
    width: 60%;
}

.bs3-img{
    flex-direction: row;
}

.bs3-internal-links{
    flex-direction: row;
}

@media only screen and (max-width:600px) {
    .b-options-main {
        justify-content: flex-start;
        margin-left: 15px;
    }

    .hero-blog-main {
        height: 550px;
    }

    .hbt-l-btn{
        display: flex;
    }

    .blogs-head-box {
        margin-top: 0px;
    }

    .hbt-right {
        display: none;
    }

    .h-blog-tags {
        padding: 0 15px;
    }

    .blog-overlay {
        align-items: flex-start;
    }

    .bl-cont {
        flex-direction: column;
        align-items: flex-start;
    }
    .blc-left h1{
        font-size: 34px;
    }

    .blc-right{
        width: 50%;
    }
    .blog-landing{
        height: 650px;
    }
    .bs1-text{
        width: 95%;
    }

    .reach-pkg-btn{
        display: none;
    }
    .hbt-left{
        flex-basis: 100%;
    }


.bs1-img{
    width: 95%;
}

.blog-sec1{
    height: 650px;
}

.blog-sec2{
    height: 850px;
}

.blog2-sec3 .bs3-img{
    width: 100%;
}

.blog2-sec3{
    height: 1500px;
}
.blog2-sec2{
    height: 1030px;
}

.bs2-img{
    width: 95%;
}

.htb-l-tags{
    display: none;
}
.bs3-text{
    width: 95%;
}
.bs3-img{
    flex-direction: column;
}

.bs3-internal-links{
    flex-direction: column;
}
}