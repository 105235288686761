.pb-right-1 .swiper {
    width: 100%;
    height: 100%;
  }


  .swiper-slide {
    text-align: center;
    font-size: 18px;
    /* background: #fff; */
    border-radius: 10px;
    overflow: hidden;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hop-btn{
    background-color: #fff;
    transition: 1s;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .hop-btn:hover{
    background-color: rgba(151, 171, 182, 0.2)

  }