/* countryBox.css */

.card-hover {
    overflow: hidden;
    /* Ensure child elements do not overflow */
    position: relative;
    /* Ensure absolute positioning works within this container */
}

.visit-btn {
    width: 120px;
    height: 120px;
    position: relative;
    bottom: 300px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 50%;
    z-index: 999;
    overflow: hidden;
    display: flex;
    justify-content: center;
    background-color: rgba(3, 21, 47, 1);
    align-items: center;
    opacity: 0;
    transition: 1s;
}

.visit-btn-bdr {
    position: relative;
    z-index: 1;
    border-radius: 50%;
    border: 2px dashed #fff;
    height: 92%;
    width: 92%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-hover:hover .visit-btn {
    transform: translate(-50%, -40%);
    opacity: 1;
}

.card-hover:hover .visit-btn-bdr {
    animation: rotate 5s linear infinite;
}

.card-hover:hover img {
    scale: 1;
}

.card-hover img {
    transition: transform 0.3s ease-in-out;
    /* Smooth transition for scaling */
}

.card-hover:hover img {
    transform: scale(1.1);
    /* Scale up image on hover */
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.visa-btn-mob {
    display: none;
}

@media only screen and (max-width:600px) {
    .visit-btn  {
        display: none;
    }

    .visa-btn-mob {
        display: flex;
    }
}