.menu-2{
  display: none;
}
.mobile-version-search{
  display: none;
}

@media only screen and (max-width:600px){
  /* *{
    overflow-x: scroll;
  } */
  
  .menu-1{
    display: none;
  }
  .menu-2{
    height: 100px;
    /* width: 100%; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    

  }
  .ham-menu-div-home{
    width: 20%;

  }

  .mobile-version-search{
    display: block;
    position: absolute;
    top: 35px;
    left: 275px;
    width: 100px
  }
  
}